import { store } from '@/store';

export default {
	name: 'isSingleProjectMixin',
	data() {
		return {
			isFilmSingle: false,
			isFilmSingleFree: false,
			isFilmSinglePaying: false
		};
	},
	mounted() {
		if (store.isFilmSingleUserFree()) {
			this.isFilmSingle = true;
			this.isFilmSingleFree = true;
		} else if (store.isFilmSingleUser()) {
			this.isFilmSingle = true;
			this.isFilmSinglePaying = true;
		}
	}
};
