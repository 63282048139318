<template>
	<div class="mb-3">
		<div
			:class="{
				'container-layout': $screen.width >= 992,
				'container-mobile': $screen.width < 992
			}"
		>
			<div class="p-0 card-collapsible">
				<div>
					<div
						class="cursor-pointer header-for-contact header-card-collapsible d-flex flex-row align-items-center"
						@click="handleClickHeader(!isCollapsed)"
					>
						<div class="w-90" :class="`${$screen.width > 576 ? 'font-weight-bold fs-16' : 'mobile-department-title'}`">{{ group.department }}</div>
						<div class="d-flex justify-content-end w-10">
							<component v-if="!isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" color="rgba(6, 38, 62, 0.74)" :size="22" />
							<component v-if="isCollapsed" :is="getLucideIcon(ICONS.CHEVRON_UP.name)" color="rgba(6, 38, 62, 0.74)" :size="22" />
						</div>
					</div>
					<b-collapse :visible="isCollapsed">
						<ContactTable :contacts="group.contacts" />
					</b-collapse>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ContactTable from './ContactTable.vue';
export default {
	name: 'contactCollapse',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		group: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isCollapsed: false
		};
	},
	components: { ContactTable },
	methods: {
		handleClickHeader(bool) {
			this.isCollapsed = bool;
		}
	}
};
</script>
<style lang="scss">
.header-for-contact {
	justify-content: space-between;
	padding: 13px 10px;
	background-color: rgba(226, 230, 235, 0.84);
}
.mobile-department-title {
	font-weight: 600;
	font-size: 1.15rem;
	color: rgba(6, 38, 62, 0.84);
}
</style>
