var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "p-0",
      class: {
        "container-layout": _vm.$screen.width >= 992,
        "container-mobile": _vm.$screen.width < 992,
      },
    },
    [
      _vm.groupedContacts.length === 0
        ? _c("div", { staticClass: "fs-16 text-center" }, [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.FormMSG(87, "Contact's not found")) + "\n\t"
            ),
          ])
        : _c(
            "div",
            _vm._l(_vm.groupedContacts, function (group) {
              return _c(
                "div",
                { key: group.department },
                [_c("ContactCollapse", { attrs: { group: group } })],
                1
              )
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }