var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticClass: "px-3",
          class: `${_vm.$screen.width > 567 ? "mt-4" : ""}`,
        },
        [
          _vm.$screen.width <= 567
            ? _c("b-col", { attrs: { cols: 12 } }, [
                _c(
                  "div",
                  {
                    staticClass: "container-mobile animated fadeIn",
                    staticStyle: { top: "95px" },
                  },
                  [
                    _c("div", { staticClass: "form content-contact-mobile" }, [
                      _c(
                        "form",
                        [
                          _vm.contactData.length === 0
                            ? _c("div", { staticClass: "text-center" }, [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.FormMSG(74, "No result"))),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.contactData, function (contact, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "pb-0" },
                              [
                                index > 0
                                  ? _c("div", {
                                      staticClass: "divider-line mt-2 mb-3",
                                    })
                                  : _vm._e(),
                                _c(
                                  "b-row",
                                  { class: { "mt-1": index === 0 } },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: { "margin-top": "2px" },
                                        attrs: { cols: "2" },
                                      },
                                      [
                                        contact.picture
                                          ? _c("div", [
                                              _c("img", {
                                                staticClass:
                                                  "img-list-contact p-0 border-0",
                                                attrs: {
                                                  src: _vm.pathPicture(
                                                    contact.picture
                                                  ),
                                                },
                                              }),
                                            ])
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "img-list-contact",
                                              },
                                              [
                                                _c("User", {
                                                  attrs: {
                                                    color: "#C4C4C4",
                                                    size: 20,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pr-0",
                                        staticStyle: { "margin-top": "4px" },
                                        attrs: { cols: "7" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "name-contact mb-0" },
                                          [
                                            _vm._v(
                                              _vm._s(contact.name) +
                                                " " +
                                                _vm._s(contact.firstName)
                                            ),
                                          ]
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                contact.customFunctionName
                                                  ? contact.customFunctionName
                                                  : contact.functionName
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {},
                                          [
                                            _c(
                                              "b-dropdown",
                                              {
                                                staticStyle: {
                                                  "border-right":
                                                    "0px !important",
                                                  "padding-top":
                                                    "0px !important",
                                                },
                                                attrs: {
                                                  "no-caret": "",
                                                  dropleft: "",
                                                  offset: "20",
                                                  variant: "none",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "button-content",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticStyle: {
                                                                "border-radius":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                variant:
                                                                  "light",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "more-vertical"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                !contact.showMyPhoneInCrew &&
                                                !contact.showMyEmailInCrew &&
                                                index == 0
                                                  ? _c(
                                                      "b-dropdown-item-button",
                                                      {
                                                        attrs: { disabled: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                25621,
                                                                "No option available"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                contact.showMyPhoneInCrew
                                                  ? _c(
                                                      "b-dropdown-item-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.callPhone(
                                                              contact
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("Phone", {
                                                          attrs: {
                                                            fill: "#06263E",
                                                            size: 16,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "   " +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                3561,
                                                                "Call"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                contact.showMyPhoneInCrew
                                                  ? _c(
                                                      "b-dropdown-item-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSendSms(
                                                              contact
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("MessageCircle", {
                                                          attrs: {
                                                            fill: "#06263E",
                                                            size: 16,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "   " +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                77887678,
                                                                "Send Sms"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                contact.showMyEmailInCrew
                                                  ? _c(
                                                      "b-dropdown-item-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.sendEmail(
                                                              contact
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("Mail", {
                                                          attrs: {
                                                            fill: "#06263E",
                                                            color: "#E2E6EB",
                                                            size: 16,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "  " +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                3562,
                                                                "Send an email"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                contact.showMyPhoneInCrew
                                                  ? _c(
                                                      "b-dropdown-item-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showVcardModal(
                                                              contact
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("Copy", {
                                                          attrs: {
                                                            fill: "#06263E",
                                                            color: "#E2E6EB",
                                                            size: 16,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "  " +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                3563,
                                                                "Copy contact"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isDeterProject === true &&
                                                _vm.haveUserAccess &&
                                                !_vm.isDisabledMobile(
                                                  contact,
                                                  index
                                                )
                                                  ? _c(
                                                      "b-dropdown-item-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.moveUpMobile(
                                                              contact,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("ArrowBigUp", {
                                                          attrs: {
                                                            fill: "#06263E",
                                                            color: "#E2E6EB",
                                                            size: 20,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " \n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                35603,
                                                                "Move up"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ])
            : _c(
                "b-col",
                { staticClass: "form", attrs: { cols: 12 } },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "5" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(3, "Type to Search"),
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { staticClass: "cursor-pointer" },
                                [
                                  _c(
                                    "b-input-group-text",
                                    { staticClass: "btn-search" },
                                    [
                                      _vm.filter.length === 0
                                        ? _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.SEARCH.name
                                            ),
                                            {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: _vm.ICONS.SEARCH.color,
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                            }
                                          )
                                        : _c(
                                            _vm.getLucideIcon(_vm.ICONS.X.name),
                                            {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: _vm.ICONS.X.color,
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.filter = ""
                                                },
                                              },
                                            }
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { cols: 12 } },
                    [
                      _vm.$screen.width < 992
                        ? _c("CardListBuilder", {
                            attrs: {
                              showFilter: false,
                              items: _vm.contactData,
                              fields: _vm.contactMobileFields,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "actions",
                                  fn: function (slotProps) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "success",
                                            disabled:
                                              !slotProps.item.showMyEmailInCrew,
                                            size: "sm",
                                            title: _vm.FormMSG(
                                              51,
                                              "Send email"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sendEmail(
                                                slotProps.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.MAIL.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: _vm.ICONS.MAIL.color,
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "success",
                                            disabled:
                                              slotProps.item.phone.length < 1 &&
                                              !slotProps.item.showMyPhoneInCrew,
                                            size: "sm",
                                            title: _vm.FormMSG(
                                              52,
                                              "Call by phone"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.callPhone(
                                                slotProps.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.PHONE.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: _vm.ICONS.PHONE.color,
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "success",
                                            disabled:
                                              slotProps.item.picture.length < 1,
                                            size: "sm",
                                            title: _vm.FormMSG(
                                              53,
                                              "View picture"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showPicture(
                                                slotProps.item.picture
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.PICTURE.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: _vm.ICONS.PICTURE.color,
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "success",
                                            disabled:
                                              slotProps.item.phone.length < 1 &&
                                              !slotProps.item.showMyPhoneInCrew,
                                            size: "sm",
                                            title: _vm.FormMSG(
                                              58765,
                                              "View vCard"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showVcardModal(
                                                slotProps.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.COPY.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: _vm.ICONS.COPY.color,
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isDeterProject === true &&
                                      _vm.haveUserAccess
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn bg-transparent border-0",
                                              attrs: {
                                                variant: "success",
                                                size: "sm",
                                                title: _vm.FormMSG(
                                                  587600,
                                                  "Move up"
                                                ),
                                                disabled:
                                                  _vm.isDisabled(slotProps),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveUp(slotProps)
                                                },
                                              },
                                            },
                                            [
                                              _c("ArrowBigUp", {
                                                attrs: {
                                                  color: "#225CBD",
                                                  size: 23,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              765209409
                            ),
                          })
                        : _vm._e(),
                      _vm.$screen.width >= 992
                        ? _c("b-table", {
                            attrs: {
                              responsive: "sm",
                              items: _vm.contactData,
                              fields: _vm.contactFields,
                              filter: _vm.filter,
                              "sticky-header": "700px",
                              "head-variant": "dark",
                              bordered: "",
                              small: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(functionName)",
                                  fn: function (data) {
                                    return [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(
                                            data.item.customFunctionName
                                              ? data.item.customFunctionName
                                              : data.item.functionName
                                          ) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(pict)",
                                  fn: function (data) {
                                    return [
                                      data.item.showMyEmailInCrew
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mr-1 btn bg-transparent border-0",
                                              attrs: {
                                                variant: "success",
                                                size: "sm",
                                                title: _vm.FormMSG(
                                                  51,
                                                  "Send email"
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendEmail(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.MAIL.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: _vm.ICONS.MAIL.color,
                                                    size: 20,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      data.item.showMyPhoneInCrew
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mr-1 btn bg-transparent border-0",
                                              attrs: {
                                                variant: "success",
                                                disabled:
                                                  data.item.phone.length < 1,
                                                size: "sm",
                                                title: _vm.FormMSG(
                                                  52,
                                                  "Call by phone"
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.callPhone(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.PHONE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.PHONE.color,
                                                    size: 20,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn bg-transparent border-0",
                                          attrs: {
                                            variant: "success",
                                            disabled:
                                              data.item.picture.length < 1,
                                            size: "sm",
                                            title: _vm.FormMSG(
                                              53,
                                              "View picture"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showPicture(
                                                data.item.picture
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.PICTURE.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color: _vm.ICONS.PICTURE.color,
                                                size: 20,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                      data.item.showMyPhoneInCrew
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn bg-transparent border-0",
                                              attrs: {
                                                variant: "success",
                                                size: "sm",
                                                title: _vm.FormMSG(
                                                  58765,
                                                  "View vCard"
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showVcardModal(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.COPY.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: _vm.ICONS.COPY.color,
                                                    size: 20,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isDeterProject === true &&
                                      _vm.haveUserAccess
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn bg-transparent border-0",
                                              attrs: {
                                                variant: "success",
                                                size: "sm",
                                                title: _vm.FormMSG(
                                                  587600,
                                                  "Move up"
                                                ),
                                                disabled: _vm.isDisabled(data),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.moveUp(data)
                                                },
                                              },
                                            },
                                            [
                                              _c("ArrowBigUp", {
                                                attrs: {
                                                  color: "#225CBD",
                                                  size: 23,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(phone)",
                                  fn: function (data) {
                                    return [
                                      data.item.showMyPhoneInCrew
                                        ? _c("div", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t" +
                                                _vm._s(data.item.phone) +
                                                "\n\t\t\t\t\t\t"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(email)",
                                  fn: function (data) {
                                    return [
                                      data.item.showMyEmailInCrew
                                        ? _c("div", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t" +
                                                _vm._s(data.item.email) +
                                                "\n\t\t\t\t\t\t"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3634951773
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(20, "Picture"),
            "ok-variant": "success",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.showContactPicture = false
            },
          },
          model: {
            value: _vm.showContactPicture,
            callback: function ($$v) {
              _vm.showContactPicture = $$v
            },
            expression: "showContactPicture",
          },
        },
        _vm._l(_vm.contactImagePath, function (oneImage, i) {
          return _c(
            "b-card",
            { key: i },
            [
              _c(
                "b-container",
                { staticClass: "p-4 bg-dark", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [_c("b-img", { attrs: { fluid: "", src: oneImage } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("VcfContactModal", {
        attrs: { contact: _vm.currContactVCard },
        on: {
          closed: function ($event) {
            _vm.currContactVCard = null
          },
        },
        model: {
          value: _vm.isVcardModalOpen,
          callback: function ($$v) {
            _vm.isVcardModalOpen = $$v
          },
          expression: "isVcardModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }