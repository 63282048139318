<template>
	<div
		:class="{
			'container-layout': $screen.width >= 992,
			'container-mobile': $screen.width < 992
		}"
		class="p-0"
	>
		<div v-if="groupedContacts.length === 0" class="fs-16 text-center">
			{{ FormMSG(87, "Contact's not found") }}
		</div>
		<div v-else>
			<div v-for="group in groupedContacts" :key="group.department">
				<ContactCollapse :group="group" />
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ContactCollapse from './ContactCollapse.vue';
export default {
	name: 'contactPerDep',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		contacts: {
			type: Array,
			required: true
		}
	},
	computed: {
		groupedContacts() {
			const groups = {};
			// Group contacts by department name
			this.contacts.forEach((contact) => {
				const department = contact.departmentName;
				if (!groups[department]) {
					groups[department] = [];
				}
				groups[department].push(contact);
			});
			// Convert groups object to an array of department objects
			const groupedArray = Object.keys(groups).map((department) => {
				return {
					department,
					contacts: groups[department]
				};
			});
			// Sort the array based on departmentPrefix and department
			groupedArray.sort((a, b) => {
				const aHasPrefix = !!a.contacts[0].departmentPrefix;
				const bHasPrefix = !!b.contacts[0].departmentPrefix;

				// Sort by prefix first (if available)
				if (aHasPrefix && !bHasPrefix) {
					return -1;
				} else if (!aHasPrefix && bHasPrefix) {
					return 1;
				} else if (aHasPrefix && bHasPrefix) {
					// Sort by departmentPrefix numerically
					const prefixA = a.contacts[0].departmentPrefix;
					const prefixB = b.contacts[0].departmentPrefix;

					if (prefixA < prefixB) {
						return -1;
					} else if (prefixA > prefixB) {
						return 1;
					}
				}

				// Sort alphabetically if both have or don't have prefix
				return a.department.localeCompare(b.department);
			});
			return groupedArray;
		}
	},
	components: { ContactCollapse },
	methods: {
		handleClickHeader(bool) {
			this.isCollapsed = bool;
		}
	}
};
</script>
