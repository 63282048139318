<template>
	<div>
		<b-row class="px-3" :class="`${$screen.width > 567 ? 'mt-4' : ''}`">
			<b-col :cols="12" v-if="$screen.width <= 567">
				<div class="container-mobile animated fadeIn" style="top: 95px">
					<div class="form content-contact-mobile">
						<form>
							<div v-if="contactData.length === 0" class="text-center">
								<p>{{ FormMSG(74, 'No result') }}</p>
							</div>
							<div v-for="(contact, index) in contactData" :key="index" class="pb-0">
								<div v-if="index > 0" class="divider-line mt-2 mb-3" />
								<b-row :class="{ 'mt-1': index === 0 }">
									<b-col cols="2" class="text-center" style="margin-top: 2px">
										<div v-if="contact.picture">
											<img :src="pathPicture(contact.picture)" class="img-list-contact p-0 border-0" />
										</div>
										<div v-else class="img-list-contact">
											<User color="#C4C4C4" :size="20" />
										</div>
									</b-col>
									<b-col cols="7" class="pr-0" style="margin-top: 4px">
										<p class="name-contact mb-0">{{ contact.name }} {{ contact.firstName }}</p>
										<p>
											<!-- {{ contact.departmentName }}
											<span class="divider-dot" /> -->
											{{ contact.customFunctionName ? contact.customFunctionName : contact.functionName }}
										</p>
									</b-col>
									<b-col cols="3" class="px-0">
										<div class="">
											<b-dropdown
												no-caret
												dropleft
												offset="20"
												variant="none"
												style="border-right: 0px !important; padding-top: 0px !important"
											>
												<template #button-content>
													<b-button style="border-radius: 5px" variant="light"><more-vertical /></b-button>
												</template>
												<b-dropdown-item-button disabled v-if="!contact.showMyPhoneInCrew && !contact.showMyEmailInCrew && index == 0">
													{{ FormMSG(25621, 'No option available') }}
												</b-dropdown-item-button>
												<b-dropdown-item-button @click="callPhone(contact)" v-if="contact.showMyPhoneInCrew">
													<Phone fill="#06263E" :size="16" /> &nbsp; {{ FormMSG(3561, 'Call') }}
												</b-dropdown-item-button>

												<b-dropdown-item-button v-if="contact.showMyPhoneInCrew" @click="handleSendSms(contact)">
													<MessageCircle fill="#06263E" :size="16" /> &nbsp; {{ FormMSG(77887678, 'Send Sms') }}
												</b-dropdown-item-button>

												<b-dropdown-item-button @click="sendEmail(contact)" v-if="contact.showMyEmailInCrew">
													<Mail fill="#06263E" color="#E2E6EB" :size="16" />&nbsp; {{ FormMSG(3562, 'Send an email') }}
												</b-dropdown-item-button>

												<b-dropdown-item-button @click="showVcardModal(contact)" v-if="contact.showMyPhoneInCrew">
													<Copy fill="#06263E" color="#E2E6EB" :size="16" />&nbsp; {{ FormMSG(3563, 'Copy contact') }}
												</b-dropdown-item-button>
												<b-dropdown-item-button
													@click="moveUpMobile(contact, index)"
													v-if="isDeterProject === true && haveUserAccess && !isDisabledMobile(contact, index)"
												>
													<ArrowBigUp fill="#06263E" color="#E2E6EB" :size="20" />&nbsp;
													{{ FormMSG(35603, 'Move up') }}
												</b-dropdown-item-button>
											</b-dropdown>
										</div>
									</b-col>
								</b-row>
							</div>
						</form>
					</div>
				</div>
			</b-col>
			<b-col class="form" :cols="12" v-else>
				<b-col cols="5" class="pb-3" v-if="$screen.width >= 992">
					<b-input-group>
						<b-form-input v-model="filter" type="text" :placeholder="FormMSG(3, 'Type to Search')" />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component
									:is="getLucideIcon(ICONS.SEARCH.name)"
									:color="ICONS.SEARCH.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									v-if="filter.length === 0"
								/>
								<component
									:is="getLucideIcon(ICONS.X.name)"
									:color="ICONS.X.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									@click="filter = ''"
									v-else
								/>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col :cols="12">
					<CardListBuilder v-if="$screen.width < 992" :showFilter="false" :items="contactData" :fields="contactMobileFields">
						<template v-slot:actions="slotProps">
							<b-button
								variant="success"
								:disabled="!slotProps.item.showMyEmailInCrew"
								size="sm"
								class="btn bg-transparent border-0"
								:title="FormMSG(51, 'Send email')"
								@click="sendEmail(slotProps.item)"
							>
								<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								:disabled="slotProps.item.phone.length < 1 && !slotProps.item.showMyPhoneInCrew"
								size="sm"
								class="btn bg-transparent border-0"
								:title="FormMSG(52, 'Call by phone')"
								@click="callPhone(slotProps.item)"
							>
								<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								:disabled="slotProps.item.picture.length < 1"
								size="sm"
								:title="FormMSG(53, 'View picture')"
								@click="showPicture(slotProps.item.picture)"
								class="btn bg-transparent border-0"
							>
								<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								:disabled="slotProps.item.phone.length < 1 && !slotProps.item.showMyPhoneInCrew"
								size="sm"
								:title="FormMSG(58765, 'View vCard')"
								@click="showVcardModal(slotProps.item)"
								class="btn bg-transparent border-0"
							>
								<component :is="getLucideIcon(ICONS.COPY.name)" :color="ICONS.COPY.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								size="sm"
								:title="FormMSG(587600, 'Move up')"
								class="btn bg-transparent border-0"
								v-if="isDeterProject === true && haveUserAccess"
								@click="moveUp(slotProps)"
								:disabled="isDisabled(slotProps)"
							>
								<ArrowBigUp color="#225CBD" :size="23" />
							</b-button>
						</template>
					</CardListBuilder>
					<b-table
						v-if="$screen.width >= 992"
						responsive="sm"
						:items="contactData"
						:fields="contactFields"
						:filter="filter"
						sticky-header="700px"
						head-variant="dark"
						bordered
						small
					>
						<template #cell(functionName)="data">
							{{ data.item.customFunctionName ? data.item.customFunctionName : data.item.functionName }}
						</template>
						<template #cell(pict)="data">
							<b-button
								variant="success"
								size="sm"
								class="mr-1 btn bg-transparent border-0"
								:title="FormMSG(51, 'Send email')"
								v-if="data.item.showMyEmailInCrew"
								@click="sendEmail(data.item)"
							>
								<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								:disabled="data.item.phone.length < 1"
								size="sm"
								class="mr-1 btn bg-transparent border-0"
								:title="FormMSG(52, 'Call by phone')"
								@click="callPhone(data.item)"
								v-if="data.item.showMyPhoneInCrew"
							>
								<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								:disabled="data.item.picture.length < 1"
								size="sm"
								:title="FormMSG(53, 'View picture')"
								@click="showPicture(data.item.picture)"
								class="btn bg-transparent border-0"
							>
								<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								size="sm"
								:title="FormMSG(58765, 'View vCard')"
								@click="showVcardModal(data.item)"
								class="btn bg-transparent border-0"
								v-if="data.item.showMyPhoneInCrew"
							>
								<component :is="getLucideIcon(ICONS.COPY.name)" :color="ICONS.COPY.color" :size="20" />
							</b-button>
							<b-button
								variant="success"
								size="sm"
								:title="FormMSG(587600, 'Move up')"
								class="btn bg-transparent border-0"
								v-if="isDeterProject === true && haveUserAccess"
								@click="moveUp(data)"
								:disabled="isDisabled(data)"
							>
								<ArrowBigUp color="#225CBD" :size="23" />
							</b-button>
						</template>
						<template #cell(phone)="data">
							<div v-if="data.item.showMyPhoneInCrew">
								{{ data.item.phone }}
							</div>
						</template>
						<template #cell(email)="data">
							<div v-if="data.item.showMyEmailInCrew">
								{{ data.item.email }}
							</div>
						</template>
					</b-table>
				</b-col>
			</b-col>
		</b-row>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(20, 'Picture')"
			v-model="showContactPicture"
			class="modal-success"
			ok-variant="success"
			ok-only
			@ok="showContactPicture = false"
		>
			<b-card v-for="(oneImage, i) in contactImagePath" :key="i">
				<b-container fluid class="p-4 bg-dark">
					<b-row>
						<b-col>
							<b-img fluid :src="oneImage" />
						</b-col>
					</b-row>
				</b-container>
			</b-card>
		</b-modal>
		<VcfContactModal v-model="isVcardModalOpen" :contact="currContactVCard" @closed="currContactVCard = null" />
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import VcfContactModal from '@/components/Modals/VcfContactModal';
import { Copy, Mail, Phone, Search, User, XCircle, MoreVertical, MessageCircle, ArrowBigUp } from 'lucide-vue';
import { SwitchCustomPositionInList } from '@/cruds/users.crud';
import DeterMixin from '@/mixins/deter.mixin';

export default {
	name: 'contactTable',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin, DeterMixin],
	props: {
		contacts: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			filter: '',
			showContactPicture: false,
			contactImagePath: [],
			isVcardModalOpen: false,
			currContactVCard: null,
			isContactCardDlownloadLoading: false,
			contactData: []
		};
	},
	components: {
		VcfContactModal,
		Search,
		MessageCircle,
		XCircle,
		Phone,
		Mail,
		User,
		Copy,
		MoreVertical,
		ArrowBigUp
	},
	watch: {
		contacts: {
			handler(contact) {
				this.sortContact(contact);
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		haveUserAccess() {
			return store.canAccessUsers();
		},
		contactMobileFields() {
			const keys = ['pict'];
			return this.contactFields.filter((t) => !keys.includes(t.key));
		},
		ContactsFilteredMap() {
			let filter = this.filter.trim().toLowerCase();
			if (filter === '') return this.contactData;
			let ar = this.contactData.filter(function (s) {
				return (
					s.name.toLowerCase().indexOf(filter) > -1 ||
					s.firstName.toLowerCase().indexOf(filter) > -1 ||
					s.email.toLowerCase().indexOf(filter) > -1 ||
					s.functionName.toLowerCase().indexOf(filter) > -1 ||
					s.departmentName.toLowerCase().indexOf(filter) > -1
				);
			});

			return ar;
		},
		contactFields() {
			return [
				{
					key: 'functionName',
					label: this.FormMSG(34, 'Function'),
					sortable: true
				},
				{
					key: 'name',
					label: this.FormMSG(30, 'Name'),
					sortable: true
				},
				{
					key: 'firstName',
					label: this.FormMSG(31, 'First Name'),
					sortable: true
				},
				{
					key: 'phone',
					label: this.FormMSG(310, 'Phone'),
					sortable: true
				},
				{
					key: 'email',
					label: this.FormMSG(32, 'Email'),
					sortable: true,
					formatter: (v, z, root) => {
						return root.showMyEmailInCrew ? v : '';
					}
				},

				{
					key: 'pict',
					label: this.FormMSG(35, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		}
	},
	methods: {
		sortContact(contact) {
			const sortedArray = contact.slice(); // Create a copy of the original array

			sortedArray.sort((a, b) => {
				const aHasPrefix = !!a.functionPrefix;
				const bHasPrefix = !!b.functionPrefix;

				// Sort by prefix first (if available)
				if (aHasPrefix && !bHasPrefix) {
					return -1;
				} else if (!aHasPrefix && bHasPrefix) {
					return 1;
				} else if (aHasPrefix && bHasPrefix) {
					// Sort by functionPrefix numerically
					const prefixA = a.functionPrefix;
					const prefixB = b.functionPrefix;

					if (prefixA < prefixB) {
						return -1;
					} else if (prefixA > prefixB) {
						return 1;
					}
				}

				// Sort alphabetically if both have or don't have prefix
				return a.functionName.localeCompare(b.functionName);
			});
			this.contactData = sortedArray;
		},
		isDisabledMobile(data, index) {
			if (index > 0 && index < this.contactData.length) {
				const previousObject = this.contactData[index - 1];
				// console.log(previousObject.functionName, data.item.functionName)
				if (previousObject && previousObject.functionName === data.functionName) {
					return false;
				}
			}
			return true;
		},
		isDisabled(data) {
			if (data.index > 0 && data.index < this.contactData.length) {
				const previousObject = this.contactData[data.index - 1];
				// console.log(previousObject.functionName, data.item.functionName)
				if (previousObject && previousObject.functionName === data.item.functionName) {
					return false;
				}
			}
			return true;
		},
		async moveUpMobile(data, index) {
			const initialIndex = index;
			let id1 = +data.id;
			let secondObjectIndex = this.contactData[initialIndex - 1];
			let id2 = +secondObjectIndex.id;
			await SwitchCustomPositionInList(id1, id2);
			const itemToMove = this.contactData.splice(initialIndex, 1)[0];
			this.contactData.splice(initialIndex - 1, 0, itemToMove);
			this.$forceUpdate();
		},
		async moveUp(data) {
			const initialIndex = data.index;
			let id1 = +data.item.id;
			let secondObjectIndex = this.contactData[initialIndex - 1];
			let id2 = +secondObjectIndex.id;
			// console.log({ initialIndex, id1, secondObjectIndex, id2 });
			await SwitchCustomPositionInList(id1, id2).then((res) => {
				if (res) {
					const itemToMove = this.contactData.splice(initialIndex, 1)[0];
					this.contactData.splice(initialIndex - 1, 0, itemToMove);
					// console.log(this.contactData)
					this.$forceUpdate();
				}
			});
		},
		handleSendSms(item) {
			window.location.href = 'sms://' + item.phone;
		},
		showVcardModal(contact) {
			this.currContactVCard = contact;
			this.isVcardModalOpen = true;
		},
		sendEmail(item) {
			//  console.log("images:", images);
			// build path for picture
			var email = item.email;
			/*var subject = this.FormMSG(45, 'Subject')
			var emailBody = this.FormMSG(46, 'Hello') + ' ' + item.firstName + ',%0D%0A%0D%0A' + store.state.myProfile.firstName + ' ' + store.state.myProfile.name
			document.location = 'mailto:' + email + '?body=' + emailBody + '&subject=' + subject */
			document.location = `mailto:${email}`;
			//'<html><p> '  + '</p></html>' ?body=Hello1&subject=test subject1"
		},
		callPhone(item) {
			window.location.href = 'tel://' + item.phone;
		},
		showPicture(image) {
			//  console.log("images:", images);
			// build path for picture
			this.contactImagePath = [];

			var fullPath = process.env.VUE_APP_GQL + '/images/' + image;
			this.contactImagePath.push(fullPath);
			this.showContactPicture = true;
		}
	}
};
</script>
<style lang=""></style>
