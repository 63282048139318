var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-3" }, [
    _c(
      "div",
      {
        class: {
          "container-layout": _vm.$screen.width >= 992,
          "container-mobile": _vm.$screen.width < 992,
        },
      },
      [
        _c("div", { staticClass: "p-0 card-collapsible" }, [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "cursor-pointer header-for-contact header-card-collapsible d-flex flex-row align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickHeader(!_vm.isCollapsed)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "w-90",
                      class: `${
                        _vm.$screen.width > 576
                          ? "font-weight-bold fs-16"
                          : "mobile-department-title"
                      }`,
                    },
                    [_vm._v(_vm._s(_vm.group.department))]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end w-10" },
                    [
                      !_vm.isCollapsed
                        ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_DOWN.name), {
                            tag: "component",
                            attrs: { color: "rgba(6, 38, 62, 0.74)", size: 22 },
                          })
                        : _vm._e(),
                      _vm.isCollapsed
                        ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name), {
                            tag: "component",
                            attrs: { color: "rgba(6, 38, 62, 0.74)", size: 22 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-collapse",
                { attrs: { visible: _vm.isCollapsed } },
                [
                  _c("ContactTable", {
                    attrs: { contacts: _vm.group.contacts },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }